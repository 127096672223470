App.Modules = App.Modules || {};
App.Modules.misc = function () {
    var o = { };

    var slick = function( data ) 
    {
        jQuery(".testimonials .container").slick({
          autoplay: true,
          autoplaySpeed: 10000,
          adaptiveHeight: false,
          nextArrow: '<div class="slick-arrow next"><i class="fa fa-angle-right"></i></div>',
          prevArrow: '<div class="slick-arrow prev"><i class="fa fa-angle-left"></i></div>',

        });

    }
    return {
        init: function() { 
        	slick();
        	return this; 
    	},
        events: function() {
            return this;
        }
    };
}();